import Field from './Field';
/**
 * Class Fieldset.
 *
 * @since 1.0.0
 */
export default class Fieldset {
    /**
     * Initializing fieldset.
     *
     * @param form Form object.
     * @param fieldset Fieldset data.
     *
     * @since 1.0.0
     */
    constructor(form, fieldset) {
        this.fields = [];
        this.form = form;
        this.name = fieldset.name;
        this.label = fieldset.label;
        this.percentage = fieldset.percentage;
        this.params = undefined === fieldset.params ? [] : fieldset.params;
        this.classes = undefined === fieldset.classes ? [] : fieldset.classes;
        this.fieldsClasses = undefined === fieldset.fieldsClasses ? [] : fieldset.fieldsClasses;
        this.conditions = fieldset.conditions;
        this.submission = fieldset.submission;
        this.nextFieldset = fieldset.nextFieldset;
        this.prevFieldset = fieldset.prevFieldset;
        fieldset.fields.forEach(field => {
            this.fields.push(new Field(this, field));
        });
    }
    conditionsFullfilled() {
        if (this.conditions === undefined) {
            return true;
        }
        let fullfillments = [];
        this.conditions.forEach((condition) => {
            let fullfilled = false;
            let field = this.form.getField(condition.field);
            switch (condition.operator) {
                case '==':
                    fullfilled = condition.value === field.getValue();
                    break;
                case '!=':
                    fullfilled = condition.value !== field.getValue();
                    break;
                case '>':
                    fullfilled = condition.value !== field.getValue();
                    break;
                case '<':
                    fullfilled = condition.value !== field.getValue();
                    break;
                default:
                    throw new Error('Operator "' + condition.operator + '" does not exist.');
            }
            fullfillments.push(fullfilled);
        });
        return !fullfillments.includes(false);
    }
    /**
     * Is there a submission to do?
     *
     * @return True if there is a submissionto do, false if not.
     *
     * @since 1.0.0
     */
    hasSubmission() {
        return this.submission !== undefined;
    }
    /**
     * Get CSS classes.
     *
     * @return String of CSS classes.
     *
     * @since 1.0.0
     */
    getClasses() {
        if (this.classes.length > 0) {
            return this.classes.join(' ');
        }
        return '';
    }
    /**
     * Get CSS field classes.
     *
     * @return String of CSS classes.
     *
     * @since 1.0.0
     */
    getFieldsClasses() {
        if (this.fieldsClasses.length > 0) {
            return this.fieldsClasses.join(' ');
        }
        return '';
    }
    /**
     * Validate fieldset.
     *
     * @return True on successful validation, false on errors.
     *
     * @since 1.0.0
     */
    validate() {
        let foundError = false;
        this.fields.forEach((field, i) => {
            if (field.validate().length > 0 && !foundError) {
                foundError = true;
            }
        });
        return foundError;
    }
    /**
     * Has fieldset validation errors.
     *
     * @return True if fieldset has errors, false if not.
     *
     * @since 1.0.0
     */
    hasValidationErrors() {
        let foundError = false;
        this.fields.forEach((field) => {
            if (field.hasValidationErrors() && !foundError) {
                foundError = true;
            }
        });
        return foundError;
    }
}
