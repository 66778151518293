import axios from 'axios';
/**
 * Submission class.
 *
 * @since 1.0.0
 */
export default class Request {
    /**
     * Submission data.
     *
     * @param submissionData Submission data.
     *
     * @since 1.0.0
     */
    constructor(url, method, data, nonce) {
        this.url = url;
        this.method = method;
        this.data = data;
        this.nonce = nonce;
    }
    send() {
        let method = this.method.toUpperCase();
        let result;
        switch (method) {
            case 'POST':
                result = this.post();
                break;
            case 'GET':
                result = this.get();
                break;
            case 'PUT':
                result = this.put();
                break;
        }
        return result;
    }
    ;
    post() {
        const options = {
            headers: { 'X-WP-Nonce': this.nonce }
        };
        return axios.post(this.url, { data: this.data }, options);
    }
    get() {
        return axios({
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'X-WP-Nonce': this.nonce
            },
            url: this.url,
            data: {
                data: this.data
            }
        });
    }
    put() {
        return axios({
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
                'X-WP-Nonce': this.nonce
            },
            url: this.url,
            data: {
                nonce: this.nonce,
                data: this.data
            }
        });
    }
}
