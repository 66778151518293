import Fieldset from './Fieldset';
import Navigation from './Navigation';
/**
 * Class Form.
 *
 * @since 1.0.0
 */
export default class Form {
    /**
     * Initializing form data.
     *
     * @param formData Formdata from JSON file.
     *
     * @since 1.0.0
     */
    constructor(formData, nonce) {
        this.sent = false;
        this.name = formData.name;
        this.classes = formData.classes;
        this.fieldsets = [];
        formData.fieldsets.forEach((fieldset) => {
            this.fieldsets.push(new Fieldset(this, fieldset));
        });
        this.navigation = new Navigation(this, formData.start, nonce);
    }
    /**
     * Get a specific fieldset.
     *
     * @param name Name of fieldset
     * @return Fieldset
     *
     * @since 1.0.0
     */
    getFieldset(name) {
        let fieldsets = this.fieldsets.filter((fieldset) => {
            return fieldset.name === name;
        });
        return fieldsets[0];
    }
    getField(name) {
        let foundField;
        this.fieldsets.forEach((fieldset) => {
            fieldset.fields.forEach((field) => {
                if (field.name === name) {
                    foundField = field;
                }
            });
        });
        return foundField;
    }
    /**
     * Has form validation errors.
     *
     * @return True if field has errors, false if not.
     *
     * @since 1.0.0
     */
    hasValidationErrors() {
        let hasValidationErrors = false;
        this.fieldsets.forEach((fieldset) => {
            if (fieldset.hasValidationErrors()) {
                hasValidationErrors = true;
                return;
            }
        });
        return hasValidationErrors;
    }
    /**
     * Get CSS Classes.
     *
     * @return String of CSS classes.
     *
     * @since 1.0.0
     */
    getClasses() {
        if (this.classes.length > 0) {
            return this.classes.join(' ');
        }
        return '';
    }
}
